@media (min-width:1700px) {
    /* .rightImg {
        left: -37%;
        width: 65rem;
    } */

}

@media (min-width:1600px) {
    /* .rightImg {
        left: -29% !important;
        width: 65rem;
    } */

}

@media (min-width: 1500px) {
    /* .rightImg {
        left: -29% !important;
    } */
}

@media (min-width:1400px) {
    .bannerHead {
        font-size: 30px;
    }
    .aboutLady {
        width: 100%;
    }
    /* .rightImg {
        left: -36%;
    } */
}
@media (max-width:1400px) {
    .aboutLady {
        width: 100%;
    }
    .memberPlansbox_ .memberHead_ {
        font-size: 25px;
    }
    .commonCartImg {
        display: block !important;
    }
    .commonCartImg img{
        height:100% ;
        max-height: 200px;
    }
    .authMenus .userName{
        max-width: 4ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (min-width: 1200px) {

    /* Start Hover Effect */
    .rolesContainer:hover {
        background: #aec1fb;
        border: 1px solid #aec1fb;
    }

    .continueField:hover {
        color: rgb(37, 87, 167);
        border-color: rgb(103, 146, 240);
        background-color: rgb(238, 241, 254);
    }

    .opptItems:hover {
        background: #1C3276;
        color: #fff !important;
    }

    .opptItems:hover .innerSubtext {
        color: #fff;
    }

    .authLinks:hover {
        color: #fff;
    }
   
}

@media (max-width: 1366px) {
    .growbusinessRight_ {
        width: 44%;
    }

    .memberMiddle {
        padding: 19px 13px;
    }
    #commentBox .dashboardComments {
        max-height: 410px;
    }
}

@media (max-width: 1280px) {
    /* .rightImg {
        left: -28%;
        width: 52rem;
        bottom: -20%;
    } */

}

@media (max-width: 1199px) {
    .memberMiddle .memberHead_ {
        font-size: 22px;
    }

    .memberMiddle {
        padding: 19px 7px;
    }

    .featureListbox_ {
        min-height: 616px;
    }
    .nav-item {
        margin-right: 0;
    }
    .nav-link {
        letter-spacing: 0px;
        padding: 8px 14px!important;
    }
    .diversityRightImg {
        position: absolute;
        right: 0;
        top: 0px;
        width: 100%;
        max-width: 586px;
    }
    .aboutMenusContainer {
        overflow: auto;
    }
    .featureListbox_ .listHead_ {
        font-size: 11px;
    }
    .memberShipSpot .wavesubText{
        font-size: 18px;
    }
}
@media (max-width:1200px){
    .homeBanner {
        padding-bottom: 9rem;
    }
    .queryBox img{
        max-width: 580px;
    }
    .queryBox {
        padding: 4rem 40px;
    }
    .aboutContainer {
        padding-top: 10rem;
    }
    .innerSidebar .secondaryBtnGrey,.secondaryBtnOuter,.primaryBtnSmall{
        padding: 10px 23px !important;
        font-size: 12px;
    }
    .authMenus .userName{
        display: none !important;
    }
    .authMenus .userDetails {
        margin: 0 0px;
    }
    .authMenus .authItems {
        margin-right: 8px;
    }
    .customColLgThree{
        width: unset !important;
    }
    .customColLgFive{
        width: unset !important;
    }

.dashboardRightArea {
    display: none;
}
}
@media (max-width:1024px){
    .navbar-brand img {
        width: 122px !important;
    }
    .queryBox {
        padding: 3rem 40px;
    }
    .queryBox img{
        max-width: 580px;
    }
    .queryBox .heading_Blue{
        font-size: 28px !important;
    }

    
}

@media (max-width: 991px) {
    .navbar-nav {
        width: 100%;
        background-color: #fff;
        padding: 35px 0px;
        min-width: 150px;
        position: absolute;
        align-items: self-start!important;
    }
   .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 15px !important;
    padding-left: 15px !important;
    color: #272727!important;
}
   .navheadInner .navbar-nav {
    top: 0;
    z-index: 9;
    }
    .navbar {
        position: initial;
    }

    .aboutFounder {
        padding: 4rem 0;
    }

    .continue::before {
        width: 24%;
    }

    .continue::after {
        width: 24%;
    }

    .bannerHead {
        font-size: 30px;
    }

    .bannerTxt {
        font-size: 14px;
    }

    .secImg {
        height: 340px;
    }

    .userDetails {
        margin: 0;
    }

    .cardsVideo {
        margin-bottom: 20px;
    }

    .noPadding {
        padding-right: 0;
    }

    .faqImgbox {
        display: none;
    }

    .order-last {
        order: 0 !important;
    }

    .liveStreame {
        margin: 29px 0 65px;
    }

    .streamImg {
        width: 66%;
    }

    .contectUs_ .memberHomeImage_ {
        padding: 45px 30px;
    }

    .contactInfo_ {
        position: relative;
        margin-bottom: 40px;
    }

    .designLeft_ .boldmainHeading_ {
        font-size: 22px;
    }

    .containerBox {
        margin: 30px 0;
        height: auto;
        padding-right:14px ;
    }

    .react-tabs__tab-list {
        text-align: right;
    }
    .aboutLady {
        margin: 0 auto;
    }

    .homeBanner .btn-group {
        margin: 5rem 0 !important;
    }

    .videoBox {
        margin: 2rem 0;
    }

    .authItems .userName {
        overflow: hidden;
        white-space: nowrap;
        width: 60px;
        text-overflow: ellipsis;
    }
    .settingLinks {
        font-size: 14px;
    }
    .navbar-toggler {
        position: relative;
    }
   .navheadInner {
    position: absolute;
    right: 0;
    top: 63px;
    z-index: 4;
    width: 100%;
}
    .bordeRight {
        margin-bottom: 20px;
    }
    .colContainer {
        margin-bottom: 20px;
    }
    .diversityRightImg {
        display: none;
    }
    .diversitySection {
        padding:50px 0 50px 0;
    }
    .certificateFaq {
        padding: 50px 0 50px 0;
    }
    .queryBox .heading_Blue {
        font-size: 27px;
    }
    .queryBox {
        padding-bottom: 0;
    }
    .queryBox img {
        max-width: 100%;
    }
    .haveQuestion{
        position: static;
    }
    .homeBanner {
        padding-bottom: 1rem;
    }
    .contectUs_ .discription_ br{
        display: none;
    }
    .featureListbox_ {
        min-height: 532px;
    }
    .landingHead_ .bannerHead{
        font-size: 32px;
    }
    .loginDetails:before {
        opacity: 0;
    }
    .deactivateDeleteBtn .subsmallBtngreen_ {
        font-size: 12px;
    }
    .authNavBar .searchBox {
        display: none;
    }
    .navbar-brand img {
        width: 236px !important;
    }
    .mobileMenu_ {
        display: block!important;
        text-align: left;
        padding: 0 30px 35px;
        width: 100%;
    }   
    
    .bottomMenu_ {
        padding: 30px 35px;
        background: #e7e7e7;
        width: 100%;
        height: 100vh;
    }
    .flexBox{
        display: none;
    }
    .mobileMenu {
        display: flex!important;
    }
    .profileBox_ .secondaryBtnOuter {
        width: 40%;
        display: inline-block;
        margin: 15px 0;
    }
    .bottomMenu_ .searchBox .searchBtn {
        position: absolute;
        right: 22px;
        top: 18px;
        left: auto;
    }
    .mobileMenu .navbar-toggler {
        padding-left: 0;
        cursor: pointer;
        outline: none!important;
        box-shadow: none!important;
    }
    .mobileMenu .nav-link {
        font-weight: 400;
      
    }
    .navbar-expand-lg .navbar-nav .bottomMenu_ .nav-link {
        color: #4a4a4a!important;
    }
    .homeBanner {
        padding: 80px 0;
    }
    .buyerService {
        padding: 100px 0;
    }
    .supplierService {
        padding: 100px 0;
    }
    .opportunites {
        padding: 100px 0;
    }
    .bussinessRelation {
        padding: 100px 0;
    }
    .sliderContainer {
        padding: 0 4%;
    }
    .slideContent {
        margin-right: 0;
    }
    .whatPeopleSay .aboutusSubtext {
        font-size: 22px;
    }
    .whatPeopleSay {
        padding: 50px 0;
    }
    .socialItems {
        margin-right: 11px;
    }

  .loginButton .nav-item a {
    color: rgba(0,0,0,.55)!important;
}
    
}

@media (max-width: 767px) {
    .continueField {
        font-size: 15px;
    }

    .authItems {
        margin-right: 10px;
    }

    .workDealing {
        padding: 0 0 0 0;
    }

    .faqcontentBox_ {
        padding: 15px 0 41px 0px;
    }

    .commonContentBox .aboutFounder {
        padding: 3em 0;
    }

    .streamImg {
        width: 100%;
    }

    .commonContentBox .aboutFounder .anotherHeading {
        font-size: 25px;
        padding-right: 0;
    }

    .designbottonContent_ {
        position: relative;

    }

    .designContent_ {
        margin: 16px 0 0;
    }

    /* .imgproduct_ {
        width: 32% !important;
    } */

    .designbottonContent_ {
        margin: 9px 0 0;
    }

    .designbtnRight {
        display: inline-block;
        margin: 11px 0 0;
    }

    .activeOpportunity_ .posts {
        display: block;
    }
    .recentlyDeleted .leftOpportunitycontent{
        width: 100%;
    }
    .activeOpportunity_ .postsDetails {
        width: 100%;
    }

    .opportunityBtn_ {
        width: 100%;
        text-align: center;
    }

    .opportunityMain_ .subsmallBtnblue_ {
        width: 60%;
    }

    .opportunityMain_ .subsmallBtnblack_ {
        width: 50%;
    }
    .activeOpportunity_ .deleteDetails {
        margin-left: 0;
    }
    .submitopportunity {
        width: 90%;
        margin: 0 auto;
    }
    .footer {
        text-align: center;
    }
    .checkBox:checked + .checkBoxTxt:after {
        top: 11%;
    }
    .loginDetails:before {
        opacity: 0;
    }
    .companyDetailsLeft .designImg_ {
        width: 32%;
    }
    .allConnection_ .postSec {
        margin: 29px 0;
    }
    .chnageProfile {
        right: 10px;
    }
    #editCompanyInformationDetails .modal-body {
        height: 100%;
        max-height: 500px;
        overflow: auto;
    }
    .stepOne,
    .stepTwo,
    .stepThree,
    .stepFour,
    .stepFive,
    .stepSix,
    .stepSeven
    {
        background-size: cover !important;
    }
    .bussinessRelLeftImg{
        display: block;
    }
    .whatPeopleSay{
        padding-bottom: 0;
    }
    .whatPeopleSay .owl-nav{
        position: static;
    }
    .whatPeopleSay .owl-carousel .owl-prev{
        position: static;
    }
    .whatPeopleSay .owl-carousel .owl-next{
        position: static;
    }
    .aboutUs .heading_Blue {
        font-size: 40px;
    }
    .bussinessResource .aboutContainer {
        padding-top: 6rem;
        padding-bottom: 2rem;
    }
    .bussinessResource .bannerHead {
        font-size: 51px;
    }
    .innerSidebar .checkBox:checked + .checkBoxTxt:after {
        top: 50%;
    }
    .profileResponsive{
        text-align: center;
    }
    .aboutprofile_{
        text-align: center;
    }
    .about15{
        text-align: center;
    }
    .missionBoard {
        padding: 4rem 0;
    }
    .newsPress {
        padding: 80px 0;
    }
    .mission {
        padding: 4rem 0;
    }
}

@media (max-width: 575px) {
   
    .text-sm-center {
        text-align: center!important;
    }
    .streamImgchat {
        bottom: -38px;
        left: 0px;

    }

    /* .secImg,
    .goIcon,
    .goIconLeft {
        display: none !important;
    } */

    .whatPeopleSay .owl-nav {
        top: 100%;
    }

    .commonContentBox .aboutFounder .anotherHeading {
        line-height: 39px;
    }

    .rightNav_ {
        justify-content: right;
        margin: 0;
    }

    /* .lastImg .peopleImg {
        display: none;
    } */

    .outerIcon3 {
        right: 40px;
        top: -53px;
    }
    .verification .detailsContainer {
        padding: 4% 5%;
    }
    .whatPeopleSay .owl-carousel .owl-next {
        bottom: 12rem;
    }
    .whatPeopleSay .owl-carousel .owl-prev {
        left: -5px;
        bottom: 12rem;
    }
    .slideContent {
        margin-right: 0px;
        padding: 0 5px;
    }
    .rightNav_ {
        display: block;
    }
    .innerSidebar .searchBox{
        margin-bottom: 0;
    }
    .searchBox {
        margin: 10px auto;
    }
    .rightNav_ .flexBox {
        justify-content: end;
    }
  
    .outerIcon2 {
        position: absolute;
        right: 20px;
        top: -10px;
    }
    .listingMenusArea {
        display: block;
        margin-bottom: 15px;
    }
    .listingMenusArea .innerSubtext {
        width:100%;
    }
    .listingItems {
        display: block;
        margin-bottom: 10px;
    }
    .whatPeopleSay .sliderContainer{
        padding: 0;
    }
    .queryBox .heading_Blue {
        font-size: 27px !important;
    }
    .containerBoard{
        flex-wrap: wrap;
    }
    .aboutContainer {
        padding-top: 5rem;
    }
    .aboutContainer .bannerHead {
        font-size: 46px;
    }
    .aboutContainer .bannerTxt {
        font-size: 21px;
    }
    .innerfawBox_ {
        padding: 40px 10px;
    }
    .selectListbox .secondaryBtnGrey{
        padding: 11px 18px !important;
    }
    .selectListbox .secondaryBtnGrey {
        padding: 11px 18px !important;
    }
    .mobileMenu_ {
        padding: 0 10px 35px;
    }
    .bottomMenu_ {
        padding: 30px 10px;
    }
    .profileBox_ .secondaryBtnOuter {
        width: 100%;
    }
    .homePage .leftSec .heading_Blue {
        margin-bottom: 25px!important;
    }
    .homeBanner .btn-group {
        margin: 3rem 0 2rem !important;
    }
    .containerBoard {
        margin-bottom: 0;
    }
    .containerBoard .boardImg {
        margin-bottom: 15px;
    }
    .missionBoard .containerBoard .innerSubtextBold {
        font-size: 19px;
        line-height: 22px;
    }
    .growBusiness_ ul li {
        font-size: 15px;
    }

    .privacyHeading {
        margin: 15px 0 5px;
    }
}


@media (max-width: 480px) {
    .startPostCon {
        padding: 13px 7px;
    }
    .postImg__ {
        width: 32px;
        height: 34px;
        margin-right: 0!important;
    }
    .growBusiness_ {
        padding: 0 0;
        margin: 0 0 !important;
    }

    .termspara_ {
        font-size: 14px;
        text-align: justify;
    }

    .growMainbox_ {
        flex-wrap: wrap;
    }

    .memberHead_ {
        font-size: 24px;
    }

    .growbusinessRight_ {
        text-align: left;
        margin: 15px 0 0 72px;
    }

    .growImage img {
        width: 56px;
        margin-right: 15px;
    }

    .headingSmall_ {
        font-size: 14px;
    }

    .otherHeading_ {
        font-size: 28px;
    }
    
    .innerSubtext {
        font-size: 14px;
    }

    .faqcontentBox_ .row-title-text {
        font-size: 16px !important;
    }

    .contactHead_ {
        font-size: 40px;
    }

    .contectUs_ .memberHomeImage_ {
        padding: 28px 17px 45px;
    }
    .businessBtn .secondaryBtnGrey, .secondaryBtnOuter {
        padding: 12px 23px !important;
    }
    /* .imgproduct_ {
        width: 53% !important;
    } */

    .designcontentTop_ {

        align-items: center;
        flex-wrap: wrap;
    }

    .statusBtn_ {
        padding: 8px 13px;
    }

    .aboutCopany_ .subsmallBtnblue_ {
        padding: 10px 17px;
        font-size: 12px;
    }

    .viewProfile_ .headTextmain_ {
        font-size: 26px;
    }
   
    .businessBtn .primaryBtnSmall {
        padding: 12px 24px !important;
    }

    .modal-content {
        max-width: 355px;
        margin: 0 auto;
    }
    .infoiconText .showinfoText {
        width: 257px;
    }
    .navheadInner {
        right: 0;
        top: 70px;
    }
    .companyDetailsLeft .designImg_ {
        width: 49%;
    }
    .queryBox .heading_Blue {
        font-size: 22px !important;
    }
    .homePage .heading_Blue br{
        display: none;
    }
    .landingHead_ .bannerHead br{
        display: none;
    }
    .queryBox {
        padding-left: 30px;
        padding-right: 30px;
    }
    .aboutLinks {
        font-size: 14px;
    }
    .aboutFounder .bannerHead {
        font-size: 31px;
    }
    .aboutFounder .bannerTxt {
        font-size: 14px;
    }
    .aboutUs .heading_Blue {
        font-size: 22px;
    }
   .registration .primaryBtn{
        font-size: 16px;
        padding: 9px 13px !important;
   }
   .selectListbox .radioCheck_ {
        margin-left: 4px;
   }
   .topPagefilter .cateSelectbox{
    max-width: 118px;
   }
   .footerLinks {
    font-size: 13px;
   }
   .membershipDetails .paymentHeading, .historyPrice, .paymentSubheading{
    font-size: 17px;
   }
   .manageUserTable th {
    font-size: 14px;
    }
    .manageUser .primaryBtn {
        padding: 11px 13px !important;
        font-size: 11px !important;
    }
    .userAvailable > p{
        font-size: 14px;
    }
    .settings{
        margin:0;
    }
    .deactivateDeleteBtn .secondaryBtnOuter{
        width: 100%;
    }
    .landingHead_ .bannerHead {
        font-size: 22px;
    }
    .landingHead_ .boldhead_ {
        line-height: normal;
        font-size: 18px;
     }
    
    .landingHead_ .boldhead_ b {
        line-height: normal;
    }
    .homeBanner .btn-group {
        margin: 2rem 0 !important;
    }
    .homePage .heading_Blue {
        font-size: 22px;
    }
    .homeRelevance .innerSubtext {
        font-size: 14px;
    }
    .buyerService {
        padding: 40px 0;
    }
    .outerBtn {
        padding: 7px 10px;
        font-size: 12px;
    }
    .supplierService, .opportunites 
    {
        padding: 40px 0;
    }
    .opportunites .innerSubtext {
        font-size: 14px;
    }
    #community .innerSubtext {
        font-size: 14px;
    }
    .socialLinks img {
        width: 27px;
        height: 27px;
    }
    .whatPeopleSay {
        padding: 40px 0;
    }
    .mission .innerSubtext {
        font-size: 14px;
    }
    .aboutList {
        font-size: 18px;
    }
    .containerBoard .innerSubtext {
        font-size: 14px;
    }
    .newsPress .bannerHead {
        font-size: 40px;
    }
    .newsCard .innerSubtext {
        font-size: 14px;
    }
    .landingHead_ .heading_Blue {
        font-size: 28px !important;
    } 
    .homeBanner.landingHead_ {
        padding: 70px 0;
    } 
    .bussinessRelation{
        padding: 70px 0;
    }
    .goIconLeft,.goIcon{
        width: 50px;
    }
    .whatPeopleSay .aboutusSubtext {
        font-size: 17px;
        line-height: 26px;
    }
    .outerPages .heading_Blue {
        font-size: 36px;
    }
}