.payment-failure {
    text-align: center;
    padding: 20px;
  }
  
.back-to-login-button {
  background-color: #4663ba;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.back-to-login-button:hover {
  background-color: #2e4eaf;
}